$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);

                // change hash
                var hash = this.hash.split('#')[1];
                window.location.hash = '#' + hash;

                return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 0
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * js-nav-button
    // *
    // *
    $(".js-nav-button").click(function(){
        $(this).toggleClass("active");
        $(".js-nav").toggleClass("active").removeClass("firstvisit");
        $(".js-header").toggleClass("activenav");

        // close window on scroll
        if ($(".js-nav").hasClass("active")) {
            var startScrollPosition = $(window).scrollTop();
            $(window).on("scroll", function() {
                if ($(window).scrollTop() >= (startScrollPosition  + 40)) {
        			$(".js-nav-button.active").trigger("click");
        		}
        	});
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * hide Claim responsive
	// *
	// *
	$(window).on("scroll load", function() {
		if ($(window).scrollTop() >= 40) {
			$(".js-header").addClass("sticky");
		}else {
			$(".js-header").removeClass("sticky");
		}
	});


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * hoverImages
	// *
	// *
    $("a[data-file]").click(function(e){
        e.preventDefault();
    });

    $("a[data-file]")
        .mouseenter(function() {
            var src = $(this).attr('href'),
                image = document.createElement("img");

            $(this).addClass("active");

            $(image).addClass("contentBlock__hoverImage").attr('src', src).appendTo($(this)).on('load', function(){
                // check and fix if image is outside/widther then document
                var docWidth = $(window).width(),
                    imageWidth = $(image).outerWidth(),
                    imagePosRight = $(image).outerWidth() + $(image).offset().left,
                    newImageWidth = (imageWidth - (imagePosRight - docWidth)) - 20;

                    if (docWidth <= imagePosRight) {
                        $(image).css("width", newImageWidth + "px");
                    }
            });
        })
        .on("mouseleave touchend", function() {
            $(this).removeClass("active").find("img").remove();
        });

    // close on touch
    $("a[data-file].active").on("click", function() {
        $(this).trigger("mouseleave");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * navigation
	// *
	// *
    $(".js-nav-item").click(function(){
        $(".js-nav-button").trigger("click");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hide intro and nav if hash is set
    // *
    // *
    var hash = window.location.hash.split('#')[1];

    if (hash) {
        sessionStorage.setItem('returnvisit', 'true');
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * show Nav and Intro on start
	// *
	// *
    var returnVisit = sessionStorage.getItem('returnvisit') ?? false;

    if (returnVisit != "true") {
        $(".js-nav-button").addClass("active");
        $(".js-nav, .js-intro").addClass("active firstvisit");
        $(".js-header").addClass("activenav");
        $("body, html").addClass("freeze");

        // hide intro automaticaly
        setTimeout(function(){
            $("body, html").removeClass("freeze");
            $("body").removeClass("firstvisit");
            $(".js-intro").addClass("hide");
        }, 4000);
    }else {
        $("body").removeClass("firstvisit");
    }

    if ($(".js-nav.firstvisit").length) {
        $(window).on("scroll", function() {
    		if ($(window).scrollTop() >= 40) {
    			$(".js-nav-button.active").trigger("click");
    		}
    	});
    }

    $(this).on("click", function(){
        $(".js-intro").addClass("hide");
        $("body, html").removeClass("freeze");
        $("body").removeClass("firstvisit");
    });

    // set return returnVisit
    sessionStorage.setItem('returnvisit', 'true');


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-button").click(function(){
        $(this).parents(".js-accordion").toggleClass("active").find(".js-accordion-body").slideToggle();
    });







});
